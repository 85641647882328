<template>
    <a-carousel :autoplay="true" dots-class="slick-dots slick-thumb" :after-change="onChange">
        <div slot="customPaging" slot-scope="props">
            <div class="page-item xs-hide" >
                <h3>{{ swipperList[props.i].title }}</h3>
                <p>{{ swipperList[props.i].subTitle }}</p>
            </div>
        </div>
        <div v-for="(item,index) in swipperList" :key="item.id" class="swipper-item" :style="{backgroundImage: 'url('+item.imgUrl+')'}" >
            <a :href="item.url" target="_blank">
                <div class="swipper-bg"></div>
                <div class="swipper-cont">                
                    <div class="swipper-title">{{ item.title }}</div>
                    <a-button type="primary" size="large" style="padding:0 50px;">更多</a-button>
                </div>
                <div class="swipper-number">
                    <span class="cur">{{ currIndex}}</span>/<span class="total">{{ total}}</span>
                </div>
            </a>
        </div>
    </a-carousel>
</template>
<script>
    export default{
        name:'swipper',
        data(){
            return{
                currIndex:'01',
                total:'',
                swipperList:[]
            }
        },
        created(){
            this.loadSwippers();
        },
        methods:{
            loadSwippers(){
                // console.log(this.$service);
                // this.$service.get('getSwippers')
                this.$service.Ers76.listSwipper({"status":1}).then(resp=>{
                    console.log("swipper ",resp);
                    this.swipperList=resp.data;
                    this.total=String(this.swipperList.length).padStart(2, '0');
                });
            },
            onChange(index) {
                this.currIndex=String(index+1).padStart(2, '0');
            },
        }
    }
</script>
<style lang="less" scoped>
    .swipper-item{
        height:640px;overflow: hidden;position:relative;background-size: cover; background-position:center;
        .swipper-bg{background-color:  rgba(51, 63, 74, 0.7);position: absolute; width:100%;height: 100%;left:0;top:0;}
        .swipper-cont{    
            position: absolute;left: 150px;top: 150px;        
            .swipper-title{color: #fff;font-weight: bold;font-size: 36px;letter-spacing: 10px;margin-bottom: 50px;}
        }
        .swipper-number{
            position: absolute;right: 150px;top: 100px;color: #fff;display:flex;align-items:center;
            .cur{font-size: 36px; font-weight: bold;margin-right: 3px;}
             .total{font-weight: bold;margin-left: 2px;}
        }

    }
    .slick-dots{
        .page-item{
            width: 200px;
            padding: 10px 10px 0 3px;
            border-top: 1px solid transparent;
            margin-right: 25px;
            p,h3{                
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: #fff;
            }
            p{
                opacity: 0.6;
            }
        }
        .slick-active {
            .page-item{border-color: @primary-color;}
            p,h3{color: @primary-color;}
        }
    }
    .ant-carousel /deep/ .slick-dots{
        // overflow: hidden;
        height: auto !important;
    }
</style>